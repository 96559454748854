<template>
  <v-container
    fluid
    :class="`py-0 ma-0 ${$vuetify.breakpoint.mobile ? 'px-4' : 'px-8'}`"
    v-resize="onResize"
  >
    <v-tour
      v-if="showTour"
      name="newSignTemplatePageTour"
      :steps="steps"
      :options="tourOptions"
      :callbacks="myCallbacks"
    >
    </v-tour>

    <v-toolbar
      :class="`mx-0 mt-4 pa-0 sign-toolbar ${
        $vuetify.breakpoint.mobile ? 'd-flex flex-column' : ''
      }`"
      flat
      dense
    >
      <v-toolbar-title
        class="text-h5 text-wrap font-weight-medium mx-0 px-0 mr-2"
        id="v-step-0"
      >
        {{ $t("applySignature.signatureModels") }}
      </v-toolbar-title>

      <v-btn
        v-if="!$vuetify.breakpoint.mobile"
        @click="getAll"
        icon
        :disabled="search !== '' || loading"
      >
        <v-icon v-text="'mdi-reload'" />
      </v-btn>
      <v-tooltip v-if="!$vuetify.breakpoint.mobile" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="showListStyle = !showListStyle"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="search !== '' || loading"
          >
            <v-icon
              v-text="
                showListStyle
                  ? 'mdi-view-grid-outline'
                  : 'mdi-view-list-outline'
              "
            />
          </v-btn>
        </template>
        <span>{{
          showListStyle
            ? $t("applySignature.viewInGrid")
            : $t("applySignature.viewInList")
        }}</span>
      </v-tooltip>
      <v-tooltip v-if="!$vuetify.breakpoint.mobile" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="setTour"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="search !== '' || loading"
          >
            <v-icon
              :small="showTour"
              v-text="showTour ? 'mdi-block-helper' : 'mdi-help-circle-outline'"
            />
          </v-btn>
        </template>
        <span>{{
          showTour
            ? $t("applySignature.turnOffTour")
            : $t("applySignature.turnOnTour")
        }}</span>
      </v-tooltip>
      <v-spacer />
      <v-btn
        color="accent"
        elevation="0"
        class="ml-4 text-none text-body-1 font-weight-medium"
        outlined
        dark
        large
        :fab="$vuetify.breakpoint.xs"
        @click="goToSignAutomation()"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp" v-text="'mdi-update'" />
        <span v-if="$vuetify.breakpoint.smAndUp">{{
          $t("applySignature.automaticApplication")
        }}</span>
      </v-btn>
      <v-btn
        color="accent"
        elevation="0"
        class="ml-4 text-none text-body-1 font-weight-medium"
        dark
        large
        :fab="$vuetify.breakpoint.xs"
        @click="create"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp" v-text="'mdi-plus'" />
        <span v-if="$vuetify.breakpoint.smAndUp">{{
          $t("applySignature.newModel")
        }}</span>
      </v-btn>
    </v-toolbar>

    <v-row class="fill-height mt-2">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5" ref="panels">
        <ResizablePage hide-footer>
          <template v-slot:table="tableProps">
            <v-card flat class="pa-0 ma-0" :max-height="tableProps.tableHeight">
              <v-card-actions
                v-if="!$vuetify.breakpoint.mobile"
                class="mb-5 px-0"
                id="v-step-6"
              >
                <v-text-field
                  :label="$t('common.searchModel')"
                  prepend-inner-icon="mdi-magnify"
                  color="accent"
                  v-model="search"
                  hide-details
                  solo
                ></v-text-field>
              </v-card-actions>

              <v-card-text
                v-if="!$vuetify.breakpoint.mobile && search"
                class="pa-0 ma-0"
              >
                <v-card
                  v-if="search && filteredTemplates.length > 0"
                  flat
                  id="filteredTemplates"
                  class="overflow-y-auto"
                  :height="tableProps.tableHeight + 88"
                >
                  <CardTemplate
                    :loading="loading"
                    v-for="(template, i) in filteredTemplates"
                    :key="i"
                    :info="template"
                    :custom="template.signature_type === 'custom'"
                    @selectCard="selectedTemplateCard = template"
                    :selected="verifyIfIsSelected(template)"
                    list-style
                    class="mb-4"
                    @edit="goSelectedTemplateCard(template)"
                    @duplicate="duplicate(template)"
                    @delete="openDeleteDialog(template)"
                  />
                </v-card>
                <div v-else class="disabled--text text-center">
                  Nenhum resultado para a pesquisa
                </div>
              </v-card-text>

              <!-- VERSÃO MOBILE -->

              <v-card-text class="pa-0 ma-0">
                <v-card-subtitle
                  class="px-0 ma-0 text-subtitle-2"
                  v-if="$vuetify.breakpoint.mobile"
                >
                  Todos os modelos
                </v-card-subtitle>
                <div
                  id="v-step-1"
                  style="width: 100%"
                  v-if="$vuetify.breakpoint.mobile"
                >
                  <v-autocomplete
                    v-model="mobileSelectModel"
                    :hide-no-data="!allTemplates"
                    :items="allTemplatesWithLabels"
                    :search-input.sync="search"
                    label="Listando todos os modelos de assinatura de e-mail"
                    solo
                    item-text="name"
                    item-color="accent"
                    return-object
                    hide-details
                    full-width
                    height="60"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon
                        color="accent"
                        left
                        class="pl-1"
                        v-text="
                          item.signature_type === 'custom'
                            ? 'mdi-pencil-box'
                            : 'mdi-card-text'
                        "
                        dense
                      />
                      <v-icon
                        v-if="item.id == company.new_users_signature"
                        color="accent"
                        left
                        v-text="'mdi-account-star-outline'"
                        class="pl-1"
                        dense
                      />
                      <span class="text-truncate">{{ item.name }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon
                        color="accent"
                        left
                        v-text="
                          item.signature_type === 'custom'
                            ? 'mdi-pencil-box'
                            : 'mdi-card-text'
                        "
                        dense
                      />
                      <v-icon
                        v-if="item.id == company.new_users_signature"
                        color="accent"
                        left
                        v-text="'mdi-account-star-outline'"
                        class="pl-1"
                        dense
                      />
                      <span class="text-truncate">{{ item.name }}</span>
                      <v-spacer />
                      <div
                        class="d-flex align-center justify-end"
                        v-if="item.is_popular"
                      >
                        <v-icon
                          small
                          class="mr-1"
                          color="accent"
                          v-text="'mdi-fire'"
                        />
                        <strong class="accent--text">
                          {{ $t("applySignature.popular") }}
                        </strong>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
                <v-expansion-panels
                  v-else-if="!search && !$vuetify.breakpoint.mobile"
                  v-model="expandTemplatesTypes"
                  :disabled="loading"
                  flat
                  mandatory
                  accordion
                >
                  <v-expansion-panel class="outlined">
                    <v-expansion-panel-header
                      id="v-step-2"
                      class="text-h6 font-weight-medium"
                      :hide-actions="expandTemplatesTypes === 0"
                    >
                      {{ $t("common.customized") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ma-0 pa-0">
                      <v-card
                        flat
                        class="ma-0 pa-0 overflow-y-auto"
                        :max-height="panelHeight"
                        min-height="190"
                        id="customTemplates"
                        :disabled="loading"
                      >
                        <v-card-text
                          class="px-0"
                          v-if="customTemplates.length === 0"
                        >
                          Nenhum modelo de assinatura personalizado
                        </v-card-text>
                        <v-card-text
                          v-else
                          class="ma-0 pa-0"
                          id="customTemplatesArea"
                        >
                          <!-- ÁREA DE MODELOS PERSONALIZADOS -->
                          <v-row class="ma-0 pa-0">
                            <v-col
                              :lg="showListStyle ? 12 : 6"
                              v-for="(companyTemplate, i) in customTemplates"
                              :key="i"
                              class="ma-0 px-0 pr-2"
                            >
                              <CardTemplate
                                :info="companyTemplate"
                                custom
                                @selectCard="
                                  selectedTemplateCard = companyTemplate
                                "
                                :selected="verifyIfIsSelected(companyTemplate)"
                                :list-style="showListStyle"
                                @edit="goSelectedTemplateCard(companyTemplate)"
                                @duplicate="duplicate(companyTemplate)"
                                @delete="openDeleteDialog(companyTemplate)"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="outlined mt-4">
                    <v-expansion-panel-header
                      id="v-step-3"
                      class="text-h6 font-weight-medium"
                      :hide-actions="expandTemplatesTypes === 1"
                    >
                      {{ $t("common.default") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card
                        flat
                        class="ma-0 pa-0 overflow-y-auto"
                        :max-height="panelHeight"
                        min-height="190"
                        id="defaultTemplates"
                        :disabled="loading"
                      >
                        <v-card-text class="ma-0 pa-0">
                          <!-- ÁREA DE MODELOS PADRÃO -->
                          <v-row class="ma-0 pa-0">
                            <v-col
                              :lg="showListStyle ? 12 : 6"
                              v-for="(defaultTemplate, i) in defaultTemplates"
                              :key="i"
                              class="ma-0 px-0 pr-2"
                            >
                              <CardTemplate
                                :info="defaultTemplate"
                                @selectCard="
                                  selectedTemplateCard = defaultTemplate
                                "
                                :selected="verifyIfIsSelected(defaultTemplate)"
                                :list-style="showListStyle"
                                @edit="goSelectedTemplateCard(defaultTemplate)"
                                @duplicate="duplicate(defaultTemplate)"
                                @delete="openDeleteDialog(defaultTemplate)"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </template>
        </ResizablePage>
      </v-col>

      <!-- PREVIEW -->
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 7">
        <ResizablePage
          outlined
          styles="pa-4"
          bodyStyle="px-3 pb-1"
          :resizable-max-width="false"
        >
          <template v-slot:header>
            <v-skeleton-loader
              v-if="loading"
              max-width="300"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-else>
              <v-card-title
                class="text-wrap"
                v-text="
                  $vuetify.breakpoint.mdAndUp && selectedName
                    ? `${$t('common.viewingModel')} ${selectedName}`
                    : `${$t('common.viewingModel')}...`
                "
              />
              <v-card-subtitle
                v-if="currentSelected && !$vuetify.breakpoint.mobile"
                v-text="
                  `${selectedDescription} ${
                    selectedDescription !== '' ? '•' : ''
                  }  ${$t('common.lastUpdate')} ${formatDateAndHour(
                    currentSelected.updated_at
                  )}`
                "
              />
            </div>
          </template>
          <template v-slot:table="tableProps">
            <TemplatePreview
              :html="hasHtml"
              :loading="loading"
              :title="selectedName"
              hide-bottom
              :hide-top="$vuetify.breakpoint.mobile"
              :maxHeight="
                $vuetify.breakpoint.mobile ? 250 : tableProps.tableHeight - 20
              "
              show-expand
              id="v-step-4"
              show-user-selection
            />
          </template>
          <!-- OPÇÕES DO MODELO -->
          <template v-slot:footer>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="6"
                xl="4"
                :order="$vuetify.breakpoint.mdAndDown ? 1 : 0"
              >
                <v-card-actions class="px-0 py-1 ma-0">
                  <v-menu offset-y top left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="v-step-7"
                        color="accent"
                        :class="`text-none px-4 text-subtitle-2 mr-4`"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!currentSelected"
                        :loading="loading"
                        @click="goUserPage(selectedTemplateCard)"
                      >
                        {{ $t("applySignature.goToApplication") }}
                      </v-btn>
                    </template>
                  </v-menu>

                  <v-menu offset-y top left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="accent"
                        :class="`text-none text-subtitle-2 px-3`"
                        text
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        id="v-step-8"
                        :disabled="!currentSelected"
                        :loading="loading"
                      >
                        {{ $t("action.edit") }}
                        <v-divider class="ml-2" vertical />
                        <v-icon
                          v-text="'mdi-menu-down'"
                          right
                          class="my-auto"
                        />
                      </v-btn>
                    </template>
                    <v-list width="200" dense>
                      <v-list-item
                        link
                        v-if="isCustom"
                        @click="goSelectedTemplateCard(selectedTemplateCard)"
                      >
                        <v-list-item-title>
                          {{
                            $t("applySignature.editModel")
                          }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item link @click="duplicate(currentSelected)">
                        <v-list-item-title>
                          {{ $t("applySignature.duplicateModel") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!$vuetify.breakpoint.lgAndUp && isCustom"
                        link
                        @click="openDeleteDialog(selectedTemplateCard)"
                      >
                        <v-list-item-title>
                          {{
                            $t("applySignature.deleteModel")
                          }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-col>
              <v-col md="12" lg="6" xl="8" class="ml-auto">
                <v-card-actions class="ma-0 pa-0">
                  <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
                  <v-switch
                    :disabled="!currentSelected || loading"
                    v-if="isConecta && currentSelected"
                    :class="`py-0 my-0 ${
                      $vuetify.breakpoint.mobile ? 'pl-2' : ''
                    } mr-5`"
                    :value.sync="selectedType"
                    @change="changeModelStatus(currentSelected.id)"
                    :true-value="'default'"
                    :false-value="'custom'"
                    :loading="loading"
                    :label="`Modelo padrão`"
                    color="accent"
                    inset
                    id="v-step-9"
                    hide-details
                  ></v-switch>
                  <v-switch
                    :disabled="!currentSelected || loading"
                    v-show="selectedType === 'default'"
                    v-if="isConecta && currentSelected"
                    :class="`py-0 my-0 ${
                      $vuetify.breakpoint.mobile ? 'pl-2' : ''
                    } mr-5`"
                    :value.sync="selectedIsPopular"
                    @change="handlePopular"
                    :true-value="true"
                    :false-value="false"
                    :loading="loading || loadingPopular"
                    :label="`Modelo popular`"
                    color="accent"
                    inset
                    id="v-step-10"
                    hide-details
                  ></v-switch>
                  <v-spacer v-if="!$vuetify.breakpoint.xl && isConecta" />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="loading"
                        :disabled="!currentSelected"
                        @click="setOrDeleteNewUserModel()"
                        v-bind="attrs"
                        v-on="on"
                        :class="`my-auto text-none font-weight-medium`"
                        id="v-step-10"
                        icon
                        large
                        :color="setIsNewUserModel ? 'accent' : 'grey darken-1'"
                      >
                        <v-icon v-text="'mdi-account-star-outline'" />
                      </v-btn>
                    </template>
                    <span>{{
                      setIsNewUserModel
                        ? $t("applySignature.uncheckModelToNewUsers")
                        : $t("applySignature.defineModelToNewUsers")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="isCustom && $vuetify.breakpoint.lgAndUp"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!currentSelected"
                        :loading="loading"
                        @click="openDeleteDialog(selectedTemplateCard)"
                        v-bind="attrs"
                        v-on="on"
                        :class="`my-auto text-none font-weight-medium`"
                        id="v-step-10"
                        icon
                        large
                        color="grey darken-1"
                      >
                        <v-icon v-text="'mdi-trash-can-outline'" />
                      </v-btn>
                    </template>
                    <span>{{ $t("applySignature.deleteModel") }}</span>
                  </v-tooltip>
                </v-card-actions>
              </v-col>
            </v-row>
          </template>
        </ResizablePage>
      </v-col>
    </v-row>

    <!-- DIALOG: CONFIRMAR EXCLUSÃO DE UM TEMPLATE -->
    <ConfirmationDialog
      :show="dialogDeleteTemplate"
      :target="template_to_delete.name"
      :action-text="$t('applySignature.deleteSignatureModel')"
      :loading="confirmDeleteLoading"
      :width="userWithTemplateApplied.length ? '700' : '500'"
      @close="dialogDeleteTemplate = false"
      @ok="exclude(template_to_delete)"
      :message="isSignatureUsedByNewUsers"
      hideReversibleText
    >
      <div v-if="userWithTemplateApplied.length">
        <Alert prominent color="warning">
          {{ $t("applySignature.deleteSignatureModelWarning") }}
        </Alert>
        <div class="users-box outlined my-4 pa-2">
          <v-chip
            v-for="user in userWithTemplateApplied"
            :key="user.email"
            pill
            class="ma-1"
          >
            <v-avatar left>
              <v-img :src="user.photo"></v-img>
            </v-avatar>
            <strong>{{ user.email }}</strong>
          </v-chip>
        </div>
      </div>
    </ConfirmationDialog>

    <!-- DIALOG: DESATIVAR O MODELO PARA NOVOS USUARIOS -->
    <ConfirmationDialog
      :show="newUserModelDialog"
      action-text="Desmarcar modelo de novos usuários"
      message="Você tem certeza que deseja desmarcar este <b>modelo de assinatura para os novos usuários?</b> Sem ele, será necessário aplicar manualmente a assinatura de todos os novos colaboradores."
      @close="cancelLeaveNewUserDialog()"
      @ok="confirmCloseNewUserDialog()"
    />

    <!-- DIALOG: CONFIRMAR SEM SALVAR -->
    <ConfirmationDialog
      :show="leavePageDialog || discartChangesConfirmation"
      action-text="Descartar alterações do modelo"
      :target="selectedName"
      @close="cancelLeavePageDialog(), (discartChangesConfirmation = false)"
      @ok="confirmLeavePageDialog(), discartChangesInCompanyTemplate()"
      unsaved-changes
    />

    <!-- MODAL PARA DEFINIR ASSINATURA DE NOVOS USUÁRIOS -->
    <NewUsersTemplateSignature
      v-if="currentSelected.id"
      :show="showNewUsersTemplateDialog"
      :template-id="currentSelected.id"
      @close="showNewUsersTemplateDialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ResizablePage from "@/components/base/ResizablePage.vue";
import NewUsersTemplateSignature from "@/components/sign/new_users_signature/NewUsersTemplateSignature";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import CardTemplate from "@/components/sign/general/CardTemplate";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import { formatDateAndHour } from "@/helpers/services/utils";
import { fluxStringToTemplate } from "@/helpers/services/tagPreview";
import { messages } from "@/helpers/variables/templates";

export default {
  name: "SignTemplates",
  components: {
    CardTemplate,
    TemplatePreview,
    ResizablePage,
    ConfirmationDialog,
    NewUsersTemplateSignature,
  },

  data() {
    return {
      messages,
      confirmDeleteLoading: false,
      panelHeight: 0,
      mobileSelectModel: null,
      search: "",
      showListStyle: false,
      expandTemplatesTypes: 0,
      selectedId: null,
      dialogDeleteTemplate: false,
      discartChangesConfirmation: false,
      selectedTemplateCard: null,
      template_to_delete: {},
      hasEditorBeenChanged: false,
      to: null,
      leavePageDialog: false,
      myCallbacks: {
        onSkip: this.setTourFalse,
        onFinish: this.setTourFalse,
        onStop: this.setTourFalse,
      },
      showTour: false,
      showNewUsersTemplateDialog: false,
      newUserModelDialog: false,
      loadingPopular: false,
    };
  },

  async beforeMount() {
    this.setLoading(true);
    await this.getAll();
    if (this.$route.query.id) {
      this.selectedId = this.$route.query.id;
    }
    this.setFirstAsSelected();
    this.setLoading(false);
  },

  beforeDestroy() {
    if (this.showTour) {
      this.$tours["newSignTemplatePageTour"].stop();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.to) {
      this.setLoading(false);
      next();
    } else {
      if (this.hasEditorBeenChanged == true) {
        this.to = to;
        this.leavePageDialog = true;
      } else {
        this.to = to;
        this.$router.push(this.to);
      }
    }
  },

  watch: {
    mobileSelectModel() {
      if (this.mobileSelectModel) {
        this.selectedTemplateCard = Object.assign({}, this.mobileSelectModel);
      }
    },
    selectedId() {
      if (this.selectedId && this.templatesIds.includes(this.selectedId)) {
        this.selectedTemplateCard = this.allTemplates.find(
          (template) => template.id === this.selectedId
        );
      }
    },
  },

  computed: {
    ...mapGetters([
      "token",
      "company",
      "customTemplates",
      "defaultTemplates",
      "allTemplates",
      "loading",
      "isConecta",
      "allTemplatesWithLabels",
      "tourLabels",
      "users",
    ]),

    hasHtml() {
      if (this.htmlToPreview) {
        return fluxStringToTemplate(this.htmlToPreview);
      }

      return "";
    },

    isSignatureUsedByNewUsers() {
      return this.currentSelected.id === this.company.new_users_signature
        ? `Essa assinatura de e-mail é usada para todos os novos colaboradores.
            Sem ela será necessário a aplicação manualmente de assinatura para
            todos os novos usuários. Caso queira manter um modelo inicial
            deve-se selecionar um novo modelo.`
        : "";
    },

    isCustom() {
      return (
        this.currentSelected && this.currentSelected.signature_type === "custom"
      );
    },

    selectedIsPopular() {
      return this.currentSelected.is_popular;
    },

    userWithTemplateApplied() {
      return this.users.filter(
        (user) => user.applied_signature_key == this.template_to_delete.id
      );
    },

    templatesIds() {
      return this.allTemplates.map((item) => item.id);
    },

    currentSelected() {
      return this.selectedTemplateCard || {};
    },

    htmlToPreview() {
      if (this.currentSelected) {
        return this.currentSelected.html;
      }
      return "";
    },

    selectedName() {
      return this.currentSelected ? this.currentSelected.name : "";
    },

    selectedDescription() {
      return this.currentSelected ? this.currentSelected.description : "";
    },

    selectedType() {
      return this.currentSelected
        ? this.currentSelected.signature_type
        : "custom";
    },

    showCustomOptions() {
      return this.selectedTemplateCard
        ? this.selectedTemplateCard.signature_type === "custom" ||
            this.isConecta
        : false;
    },

    filteredTemplates() {
      return this.allTemplates.filter((item) =>
        item.name.toUpperCase().includes(this.search.toUpperCase())
      );
    },

    steps() {
      return [
        {
          show: true,
          target: "#v-step-0",
          header: {
            title: "Bem vindo!",
          },
          content:
            "Descubra uma nova maneira de gerenciar seus modelos de assinatura com o Modelos de Assinatura do Conecta Suite.",
        },
        {
          show: this.$vuetify.breakpoint.mobile,
          target: "#v-step-1",
          header: {
            title: "Modelos",
          },
          content:
            "Navegue pelos modelos disponíveis e visualize em tempo real a aparência de cada modelo!",
        },
        {
          show: !this.$vuetify.breakpoint.mobile,
          target: "#v-step-2",
          header: {
            title: "Modelos personalizados",
          },
          content:
            "Os seus modelos de assinaturas de e-mail a um clique que distância! Crie e gerencie modelos personalizados com a marca da sua empresa.",
        },
        {
          show: !this.$vuetify.breakpoint.mobile,
          target: "#v-step-3",
          header: {
            title: "Modelos padrão",
          },
          content:
            "Descubra com os modelos padrão, a simplicidade e elegância combinadas para assinaturas completas e robustas. Aplique ou crie uma cópia do seu modelo preferido e deixe tudo ainda mais com a marca da sua empresa.",
        },
        {
          show: !this.$vuetify.breakpoint.mobile,
          target: "#v-step-4",
          header: {
            title: "Visualize",
          },
          content:
            "Escolha o modelo desejado e visualize como ele será exibido em sua mensagem de e-mail.",
        },
        {
          show: true,
          target: "#v-step-5",
          header: {
            title: "Configurações de visualização",
          },
          content:
            "Veja a assinatura em tela cheia ou visualize esta no tema escuro",
        },
        {
          show: !this.$vuetify.breakpoint.mobile,
          target: "#v-step-6",
          header: {
            title: "Encontre o que estava procurando",
          },
          content:
            "Navegue facilmente entre os modelos realizando pesquisas pelo modelo desejado.",
        },
        {
          show: true,
          target: "#v-step-7",
          header: {
            title: "Aplique aos usuários",
          },
          content:
            "Aplique modelos para múltiplos ou um único usuário, simples e prático!",
        },
        {
          show: this.showCustomOptions,
          target: "#v-step-8",
          header: {
            title: "Mãos à massa!",
          },
          content:
            this.isCustom && !this.$vuetify.breakpoint.lgAndUp
              ? "Edite, duplique e exclua seus modelos personalizados"
              : "Escolha entre editar o modelo selecionado ou criar uma cópia dele para editar posteriormente!",
        },
        {
          show: this.isConecta,
          target: "#v-step-9",
          header: {
            title: "Disponibilizar como Padrão",
          },
          content:
            "Libere ou bloqueie o a possibilidade de outras empresas aplicar ou duplicar este modelo com um clique!",
        },
        {
          show: this.isCustom && this.$vuetify.breakpoint.lgAndUp,
          target: "#v-step-10",
          header: {
            title: "Não gostou deste modelo?",
          },
          content:
            "Não se preocupe! Você pode excluí-lo a qualquer momento clicando aqui",
        },
      ].filter((item) => item.show);
    },

    tourOptions() {
      return {
        highlight: false,
        labels: this.tourLabels,
      };
    },

    setIsNewUserModel() {
      return this.currentSelected.id == this.company.new_users_signature;
    },
  },

  methods: {
    formatDateAndHour,

    ...mapActions([
      "changeModelType",
      "getDefaultTemplates",
      "getCustomTemplates",
      "setNewUsersTemplate",
      "excludeSignatureTemplate",
      "setSignaturePopular",
    ]),

    ...mapMutations(["setLoading", "setSnackBar"]),

    async handlePopular() {
      this.loadingPopular = true;

      const template = await this.setSignaturePopular(this.currentSelected);
      this.selectedTemplateCard = template;

      this.loadingPopular = false;
    },

    onResize() {
      if (!this.$vuetify.breakpoint.mobile) {
        this.panelHeight =
          window.innerHeight -
          this.$refs.panels.getBoundingClientRect().y -
          53 * 5.5;
      }
    },

    goToSignAutomation() {
      this.$router.push({ path: "/sign/users/sign-automation" });
    },

    setTourFalse() {
      if (this.showTour) {
        this.showTour = false;
      }
    },

    setTour() {
      if (this.showTour) {
        this.showTour = false;
        return;
      }
      this.showTour = true;
      setTimeout(() => {
        this.$tours["newSignTemplatePageTour"].start();
      }, 500);
    },

    async getAll() {
      this.setLoading(true);
      await this.getCustomTemplates();
      await this.getDefaultTemplates();
      this.setLoading(false);
    },

    setFirstSelectedAfterExclude() {
      let domElement = null;

      if (this.selectedTemplateCard.signature_type === "custom") {
        this.selectedTemplateCard = this.customTemplates[0];
        this.mobileSelectModel = this.customTemplates[0];
        this.expandTemplatesTypes = 0;
        domElement = document.getElementById("customTemplates");
      } else {
        this.selectedTemplateCard = this.defaultTemplates[0];
        this.mobileSelectModel = this.defaultTemplates[0];
        this.expandTemplatesTypes = 1;
        domElement = document.getElementById("defaultTemplates");
      }

      if (domElement) {
        domElement.scrollTo({ top: 0, behavior: "smooth" });
      }
    },

    setFirstAsSelected() {
      let domElement = null;

      if (this.customTemplates.length > 0) {
        this.selectedTemplateCard = this.customTemplates[0];
        this.mobileSelectModel = this.customTemplates[0];
        this.expandTemplatesTypes = 0;
        domElement = document.getElementById("customTemplates");
      } else {
        this.selectedTemplateCard = this.defaultTemplates[0];
        this.mobileSelectModel = this.defaultTemplates[0];
        this.expandTemplatesTypes = 1;
        domElement = document.getElementById("defaultTemplates");
      }

      if (domElement) {
        domElement.scrollTo({ top: 0, behavior: "smooth" });
      }
    },

    verifyIfIsSelected(template) {
      return this.currentSelected
        ? this.currentSelected.id === template.id
        : false;
    },

    async changeModelStatus(model_id) {
      this.setLoading(true);
      await this.changeModelType(model_id)
        .then(({ data }) => {
          this.selectedTemplateCard = data;
          const isCustom =
            this.selectedTemplateCard.signature_type === "custom";
          this.expandTemplatesTypes = isCustom ? 0 : 1;
          this.getAll();
          this.setSnackBar({
            show: true,
            color: "success",
            message: isCustom
              ? messages.success_removing_default
              : messages.success_making_default,
          });
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: messages.error_change_default,
          });
        });
      this.setLoading(true);
    },

    goUserPage(item) {
      this.setLoading(true);
      this.selectedTemplateCard = item;
      this.$router.push({
        path: "/sign/users",
        query: {
          id: item.id,
        },
      });
      this.setLoading(false);
    },

    openDeleteDialog(templateToDelete) {
      this.dialogDeleteTemplate = true;
      this.template_to_delete = templateToDelete;
    },

    create() {
      this.$router.push({ name: "SignSelectEditor" });
    },

    async duplicate(template = null) {
      let templateToCopy = template || this.currentSelected;

      this.setLoading(true);

      let url = process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template";

      const { name, description, html } = templateToCopy;

      let params = {
        description: description,
        name: name ? `${name} [CÓPIA]` : "[CÓPIA]",
        html: html,
      };

      if (template.editor) {
        params.editor = template.editor;
        params.signature_structure = template.signature_structure || "left";
      }

      let auth = { headers: { Authorization: this.token } };

      await this.$axios
        .post(url, params, auth)
        .then(({ data }) => {
          this.selectedId = data.key;

          if (data.editor === "rich_text" && this.isConecta) {
            this.$router.push(`/sign/email-signature/${this.selectedId}/edit`);
          } else if (data.editor === "rich_text" && !this.isConecta) {
            this.$router.push(`/sign/email-signature/${this.selectedId}`);
          } else {
            this.$router.push(`/sign/email-signature/${this.selectedId}/edit`);
          }

          return true;
        })
        .catch((error) => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: messages.error_duplicate_template,
          });

          console.error("duplicateTemplate(): ", error);
          return false;
        });

      this.setLoading(false);
    },

    goSelectedTemplateCard(item) {
      this.selectedTemplateCard = item;

      const isRichText = item.editor === "rich_text";

      if (isRichText && this.isConecta) {
        this.$router.push(`/sign/email-signature/${item.id}/edit`);
      } else if (isRichText && !this.isConecta) {
        this.$router.push(`/sign/email-signature/${item.id}`);
      } else if (item.editor) {
        this.$router.push(`/sign/email-signature/${item.id}/edit`);
      } else {
        this.$router.push(`/sign/email-signature/${item.id}`);
      }
    },

    async exclude(template_to_delete) {
      this.confirmDeleteLoading = true;
      await this.excludeSignatureTemplate(template_to_delete.id).then(
        async () => {
          this.dialogDeleteTemplate = false;
          this.confirmDeleteLoading = false;
          await this.getAll();
          this.setFirstSelectedAfterExclude();
        }
      );
    },

    discartChangesInCompanyTemplate() {
      this.getCustomTemplates();
      this.hasEditorBeenChanged = false;
      this.discartChangesConfirmation = false;
      this.selectedTemplateCard = null;
      this.selectedId = null;
      this.setFirstAsSelected();
      // this.$router.push("/sign/template");
      window.scrollTo(0, 0);
    },

    cancelLeavePageDialog() {
      this.leavePageDialog = false;
      this.to = null;
    },

    confirmLeavePageDialog() {
      if (this.leavePageDialog) {
        this.leavePageDialog = false;
        this.$router.push(this.to);
      }
    },

    cancelLeaveNewUserDialog() {
      this.newUserModelDialog = false;
    },

    confirmCloseNewUserDialog() {
      if (this.newUserModelDialog) {
        this.newUserModelDialog = false;
        this.setNewUsersTemplate(null);
      }
    },

    // Ativar ou desativar como modelo para novos usuários
    setOrDeleteNewUserModel() {
      if (this.currentSelected.id == this.company.new_users_signature) {
        this.newUserModelDialog = true;
      } else {
        this.showNewUsersTemplateDialog = true;
      }
    },
  },
};
</script>
<style>
.users-box {
  height: 70px;
  max-height: 100px;
  overflow-y: scroll;
}
</style>
